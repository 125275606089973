import { fabric } from "fabric";
import Mousetrap from "mousetrap";
import {
  updateGridBackgroundPosition,
  updateGridBackground,
} from "../fabric/viewport";
import type { SelectableCanvas } from "./mousePan";

/**
 * State structure we are temporarily disabling for panning in Fabric
 */
type PreviousState = {
  readonly evented: boolean;
  readonly selectable: boolean;
};

type Previous = { readonly [key: string]: PreviousState };

interface FabricObject extends fabric.Object {
  readonly id?: string;
}

/**
 * Pan canvas viewport up 10 points
 */
export const moveUp = (canvas: SelectableCanvas<KeyboardEvent>) => (): void => {
  canvas.relativePan(new fabric.Point(0, -10));
  canvas.requestRenderAll();

  canvas.viewportTransform && updateGridBackground(canvas.viewportTransform);
};

/**
 * Pan canvas viewport down 10 points
 */
export const moveDown =
  (canvas: SelectableCanvas<KeyboardEvent>) => (): void => {
    canvas.relativePan(new fabric.Point(0, 10));
    canvas.requestRenderAll();

    canvas.viewportTransform && updateGridBackground(canvas.viewportTransform);
  };

/**
 * Pan canvas viewport left 10 points
 */
export const moveLeft =
  (canvas: SelectableCanvas<KeyboardEvent>) => (): void => {
    canvas.relativePan(new fabric.Point(-10, 0));
    canvas.requestRenderAll();

    canvas.viewportTransform && updateGridBackground(canvas.viewportTransform);
  };

/**
 * Pan canvas viewport right 10 points
 */
export const moveRight =
  (canvas: SelectableCanvas<KeyboardEvent>) => (): void => {
    canvas.relativePan(new fabric.Point(10, 0));
    canvas.requestRenderAll();

    canvas.viewportTransform && updateGridBackground(canvas.viewportTransform);
  };

export type PanEvent = readonly [
  "up" | "down" | "left" | "right",
  () => void,
  "keypress" | "keydown" | "keyup" | undefined
];

type PanEvents = readonly PanEvent[];

/**
 * Keyboard Pan event binds
 * up, down, left, right - Pan the canvas using the keyboard.
 */
export const keyboardPanEvents = (
  canvas: SelectableCanvas<KeyboardEvent>
): PanEvents => {
  return [
    ["up", moveUp(canvas), undefined],
    ["down", moveDown(canvas), undefined],
    ["left", moveLeft(canvas), undefined],
    ["right", moveRight(canvas), undefined],
  ];
};

/**
 * Bind events to Mousetrap instance
 */
export const bindMousetrap = (events: PanEvents): (() => void) => {
  const mousetrap = new Mousetrap();

  events.map(([key, eventHandler, action]) =>
    mousetrap.bind(key, eventHandler, action)
  );

  return function unbind(): void {
    events.map(([key]) => mousetrap.unbind(key));
  };
};

export const bindKeyboardPan = (
  canvas: SelectableCanvas<KeyboardEvent>
): (() => void) => bindMousetrap(keyboardPanEvents(canvas));

export default bindKeyboardPan;
