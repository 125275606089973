/* eslint-disable max-lines */
/* tslint:disable */
// generated by typescript-json-validator
// edited by Dave,
//   consider the differences in
//   the validations from the automated validator
//   specifically referencing common patterns
// import {inspect} from 'util';
import Ajv, { ValidateFunction as AjvValidateFunction } from "ajv";
import { RootState } from "./configureStore";
export const ajv = new Ajv({
	allErrors: true,
	coerceTypes: false,
	allowUnionTypes: true,
	// useDefaults: true,
});

ajv.addMetaSchema(require("ajv/lib/refs/json-schema-draft-06.json"));

export const RootStateSchema = {
	$schema: "http://json-schema.org/draft-07/schema#",
	definitions: {
		layer: {
			properties: {
				lock: {
					type: "boolean",
				},
				visible: {
					type: "boolean",
				},
			},
			type: "object",
		},
		Layers: {
			properties: {
				sitePlan: { $ref: "#/definitions/layer" },
				hardSurfaces: { $ref: "#/definitions/layer" },
				herbaceous: { $ref: "#/definitions/layer" },
				labels: { $ref: "#/definitions/layer" },
				shrubs: { $ref: "#/definitions/layer" },
				smallTrees: { $ref: "#/definitions/layer" },
				structures: { $ref: "#/definitions/layer" },
				surfaces: { $ref: "#/definitions/layer" },
				trees: { $ref: "#/definitions/layer" },
			},
			type: "object",
		},
		object: {
			type: "object",
			required: ["id"],
			properties: {
				angle: {
					description: "Rotation angle",
					type: "number",
				},
				height: {
					description: "Height",
					type: "number",
				},
				id: {
					description: "UUID",
					// type: "string",
				},
				label: {
					description: "Label",
					type: "string",
				},
				left: {
					description: "Positioning left",
					type: ["number", "null"],
				},
				originX: {
					anyOf: [
						{
							enum: ["center", "left", "right"],
							type: "string",
						},
						{
							type: "number",
						},
					],
					description: "originX allows for 0-1 positioning of the origin",
				},
				originY: {
					anyOf: [
						{
							enum: ["center", "left", "right"],
							type: "string",
						},
						{
							type: "number",
						},
					],
					description: "originY allows for 0-1 positioning of the origin",
				},
				scaleX: {
					description: "Scale in the X axis",
					type: "number",
				},
				x1: {
					description: "x position of the first point of a line",
					type: "number",
				},
				y1: {
					description: "y position of the first point of a line",
					type: "number",
				},
				x2: {
					description: "x position of the second point of a line",
					type: "number",
				},
				y2: {
					description: "y position of the second point of a line",
					type: "number",
				},
				scaleY: {
					description: "Scale in the Y axis",
					type: "number",
				},
				subtype: {
					description: "Type of shape (lawn, perennial)",
					enum: [
						"asphalt",
						"bench",
						"bushy",
						"chair",
						"concrete",
						"flagstone",
						"deciduous-tree",
						"deck",
						"dining-table",
						"evergreen-shrub",
						"evergreen-tree",
						"fruit-tree",
						"grass",
						"gravel",
						"house",
						"car",
						"label",
						"lawn",
						"leaves",
						"line",
						"image",
						"mulch",
						"patio",
						"label-draw",
						"perennial",
						"shrub",
						"small-tree",
						"site-plan-draw",
						"table",
					],
					type: "string",
				},
				top: {
					description: "Positioning top",
					type: "number",
				},
				type: {
					description: "Type of basic shape (group, rect, circle, polygon)",
					type: "string",
				},
				width: {
					description: "Width",
					type: "number",
				},

				points: {
					description: "Points used in polygon and polyline shapes",
					type: "array",
					items: {
						type: "object",
						properties: { x: { type: "number" }, y: { type: "number" } },
					},
				},
				path: {
					description: "Path commands to draw a path",
					type: "array",
					items: { type: "array", items: { type: ["string", "number"] } },
				},
				pathOffset: {
					description: "Path offset for polygons and path commands",
					type: "object",
					properties: {
						x: {
							type: "number",
						},
						y: {
							type: "number",
						},
					},
				},
			},
		},
		"StateWithHistory<readonlyEcogardenObject[]>": {
			properties: {
				_latestUnfiltered: {
					items: { $ref: "#/definitions/object" },
					type: "array",
				},
				future: {
					items: {
						items: { $ref: "#/definitions/object" },
						type: "array",
					},
					type: "array",
				},
				group: {},
				index: {
					type: "number",
				},
				limit: {
					type: "number",
				},
				past: {
					items: {
						items: { $ref: "#/definitions/object" },
						type: "array",
					},
					type: "array",
				},
				present: {
					items: { $ref: "#/definitions/object" },
					type: "array",
				},
			},
			required: [
				// "future",
				// "past",
				// "present"
			],
			type: "object",
		},
	},
	properties: {
		canvas: {
			type: "string",
		},
		designId: {
			type: ["string"],
		},
		layers: {
			$ref: "#/definitions/Layers",
		},
		map: {
			properties: {
				lat: {
					type: "number",
				},
				lng: {
					type: "number",
				},
				zoom: {
					type: "number",
				},
			},
			required: ["lat", "lng", "zoom"],
			type: "object",
		},
		objects: {
			type: ["array", "object"],
			oneOf: [
				{
					$ref: "#/definitions/StateWithHistory<readonlyEcogardenObject[]>",
				},
				{ items: { $ref: "#/definitions/object" }, type: "array" },
			],
		},
		pan: {
			type: "boolean",
		},
		panels: {
			properties: {
				selection: {
					type: "boolean",
				},
				settings: {
					type: "boolean",
				},
				shapes: {
					type: "boolean",
				},
			},
			required: ["selection", "settings", "shapes"],
			type: "object",
		},
		settings: {
			properties: {
				debug: {
					type: "boolean",
				},
				name: {
					type: "string",
				},
			},
			required: ["debug", "name"],
			type: "object",
		},
		viewport: {
			items: {
				type: "number",
			},
			type: "array",
		},
		zoom: {
			type: "number",
		},
	},
	required: [],
	type: "object",
};
export type ValidateFunction<T> = ((data: unknown) => data is T) &
	Pick<AjvValidateFunction, "errors">;
export const isRootState = ajv.compile(
	RootStateSchema
) as ValidateFunction<RootState>;
export default function validate(value: unknown): RootState {
	if (isRootState(value)) {
		return value;
	} else {
		throw new Error(
			ajv.errorsText(
				isRootState.errors!.filter((e: any) => e.keyword !== "if"),
				{ dataVar: "RootState" }
			) +
				"\n\n" +
				JSON.stringify(value)
		);
	}
}

export type { RootState } from "./configureStore";
