export const directions = {
  up: "up",
  down: "down",
  left: "left",
  right: "right",
} as const;

export type Direction = typeof directions[keyof typeof directions];

/**
 * Find the direction from the Mousetrap action
 * @param action up, down, left, right
 */
export const findDirectionFromAction = (
  action: Direction
): Direction | undefined => {
  return directions[action];
};

/**
 * Get direction from event key
 */
export const getDirectionFromEvent = (event: {
  readonly key: Direction;
}): Direction | undefined => findDirectionFromAction(event.key);

/**
 * Calculate the Direction Offset for a fabric.Object
 */
export const calculateOffsetMovement =
  (direction: Direction) =>
  (offset: number) =>
  (o: fabric.Object): fabric.IObjectOptions => {
    if (o.top === undefined || o.left === undefined) {
      return {};
    }

    if (direction === directions.up) {
      return { top: o.top - offset };
    }

    if (direction === directions.down) {
      return { top: o.top + offset };
    }

    if (direction === directions.left) {
      return { left: o.left - offset };
    }

    if (direction === directions.right) {
      return { left: o.left + offset };
    }

    return {};
  };
