import { TypedUseSelectorHook, useSelector } from "react-redux";
import {
	applyMiddleware,
	CombinedState,
	combineReducers,
	createStore,
	Reducer,
	Store,
} from "redux";
import { configureStore as toolkit_configureStore } from "@reduxjs/toolkit";
import { composeWithDevTools } from "@redux-devtools/extension";
import thunkMiddleware from "redux-thunk";
import undoable, { StateWithHistory } from "redux-undo";
import { Viewport } from "../actions/viewport";
// Reducers
import canvasReducer from "../reducers/canvasReducer";
import { copyReducer } from "../reducers/copyReducer";
import { layersReducer } from "../reducers/layersReducer";
import { Features, featuresReducer } from "../reducers/featuresReducer";
import { mapReducer, MapState } from "../reducers/mapReducer";
import { objectsReducer } from "../reducers/objectsReducer";
import panelsReducer from "../reducers/panelsReducer";
import { panReducer } from "../reducers/panReducer";
import { settingsReducer } from "../reducers/settingsReducer";
import { viewportReducer } from "../reducers/viewportReducer";
import { zoomReducer, ZoomState } from "../reducers/zoomReducer";
import { LayersState } from "../shapes";
import { EcogardenObjects } from "./objects";
import { designsReducer } from "../reducers/designsReducer";
import { tauriReducer, TauriState } from "../reducers/tauriReducer";

/**
 * Application state
 */
export type RootState = {
	readonly canvas: string;
	readonly copy: readonly EcogardenObjects[];
	readonly designId: string;
	readonly features: { readonly [K in Features]?: boolean };
	readonly layers: LayersState;
	readonly map: MapState;
	readonly panels: {
		readonly selection: boolean;
		readonly settings: boolean;
		readonly shapes: boolean;
	};
	readonly pan: boolean;
	readonly objects: StateWithHistory<readonly EcogardenObjects[]>;
	readonly settings: { readonly debug: boolean; readonly name: string };
	readonly tauri: TauriState;
	readonly viewport: Viewport;
	readonly zoom: ZoomState;
};

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type PreloadedState = Partial<RootState>;

export const compileReducers = (): Reducer<CombinedState<RootState>> => {
	return combineReducers({
		canvas: canvasReducer,
		copy: copyReducer,
		designId: designsReducer,
		features: featuresReducer,
		map: mapReducer,
		panels: panelsReducer,
		pan: panReducer,
		layers: layersReducer,
		objects: undoable(objectsReducer, {
			limit: 50,
		}),
		settings: settingsReducer,
		tauri: tauriReducer,
		viewport: viewportReducer,
		zoom: zoomReducer,
	});
};

const composeEnhancers = composeWithDevTools({ trace: true });
export const enhancers = composeEnhancers(applyMiddleware(thunkMiddleware));

export const configureStore = (
	preloadedState: Readonly<Partial<RootState>>
): Store<RootState> => {
	const reducer = compileReducers();

	const store = toolkit_configureStore({
		reducer,
		preloadedState,
		middleware: [thunkMiddleware],
		devTools: { trace: true },
	});

	return store;
};
