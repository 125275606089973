/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import { fabric } from "fabric";
import debounce from "lodash/debounce";
import throttle from "lodash/throttle";
import { batch } from "react-redux";
import type { Dispatch, Store } from "redux";
import { modifiedObject } from "../actions/objects";
import * as viewportActions from "../actions/viewport";
import * as zoomActions from "../actions/zoom";
import "../design/fabric";
// import {
//   handleCopyKeybind,
//   handleEscapeKeybind,
//   handlePasteKeybind,
// } from "../design/keybindEvents";
import { syncCanvasWithWindow } from "../lib/canvasEvents";
import type { RootState } from "../lib/configureStore";
import {
  EcogardenObject,
  EcogardenObjects,
  toEcogardenObject,
} from "../lib/objects";
import { EcogardenCanvas } from "./fabric";
import { loadObjects } from "./fabric/canvas";
import {
  compareFill,
  convertFillColor,
  EcogardenFabricObject,
  EcogardenFabricObjects,
  EcogardenFabricPolygon,
  EcogardenFabricTextbox,
} from "./fabric/objects";
import { updateGridBackground } from "./fabric/viewport";
import {
  FabricZoomLevel,
  fabricZoomLevelToOSMZoomLevel,
  OSMZoomLevelToFabricZoomLevel,
  ZoomFabricEvent,
  ZoomFabricEventHandlers,
} from "./fabric/zoom";
import log, { LogLevel } from "./log";
import { PathSizes } from "./path";
import { profiler, Profiling } from "./profiling";
import { sizeToNumber, TextSizes } from "./text";
import { sizeToNumber as pathSizeToNumber } from "./path";
import { Point } from "./vector";
import { OSMZoomLevel } from "./zoom";

/**
 * Add events to the window
 * Ex: resize to resize canvas
 */
export const setupWindowEvents = (
  canvas: fabric.StaticCanvas
): (() => void) => {
  const events: readonly (readonly ["resize", () => void])[] = [
    // Resize the canvas to the window size when the window changes size
    ["resize", debounce(() => syncCanvasWithWindow(canvas), 250)],
  ];

  log.debug("Setting up window events.");

  events.map(([key, handler]) => window.addEventListener(key, handler));

  return function cleanup(): void {
    events.map(([key, handler]) => window.removeEventListener(key, handler));
  };
};

/**
 * Save when the object is modified on the canvas
 */
export const saveState =
  (store: Store<RootState>) =>
  (canvas: EcogardenCanvas) =>
  (e: fabric.IEvent): void => {
    // update store with updated object
    const object: EcogardenFabricObject | undefined = e.target;

    if (!object) {
      log.debug(() => "Invalid object to save");
      return;
    }

    // if an active selection, we are saving the individual objects in the selection
    if (object.type === "activeSelection") {
      batch(() => {
        (object as fabric.ActiveSelection).getObjects().forEach((o) => {
          store.dispatch(modifiedObject(toEcogardenObject(o)));
        });
      });
    } else {
      store.dispatch(modifiedObject(toEcogardenObject(object)));
    }
  };

/**
 * Add canvas events to the canvas
 * Ex: object:modified to save object states to storage
 */
export const setupCanvasEvents =
  (store: Store) =>
  (
    canvas: fabric.Canvas & ZoomFabricEventHandlers<fabric.StaticCanvas>
  ): (() => void) => {
    const events: readonly (readonly [
      "object:modified" | "zoomto" | "selection:cleared" | "before:render",
      ((e: fabric.IEvent) => void) | ((e: ZoomFabricEvent) => void)
    ])[] = [
      [
        "object:modified",
        throttle(saveState(store)(canvas as EcogardenCanvas), 16),
      ],
      // ["zoomto", handleZoom(store)(canvas)],
    ];

    events.map(([key, handler]) => canvas.on(key, handler));

    return function cleanup(): void {
      events.map(([key, handler]) => canvas.off(key, handler));
    };
  };

export const toJSON = ({
  objects,
  viewport,
  settings,
  features,
	designId,
}: RootState): string =>
  JSON.stringify({ objects: objects.present, viewport, settings, designId });

// https://stackoverflow.com/questions/68742597/preserve-key-type-when-using-object-entries
interface ObjectEntriesWithTypedKeys {
  entries<T>(o: { [K in keyof T]: T[K] }): [keyof T, T[keyof T]][];
}

export type Reconcilation = {
  simular: Partial<EcogardenFabricObject>[];
  added: EcogardenObject[];
  removed: string[];
};

const isInSelection = (canvas: fabric.Canvas) => (object: { id?: string }) => {
  return canvas.getActiveObjects().find(({ id }) => id === object.id)
    ? true
    : false;
};

const isLocked = ({
  selectable,
  evented,
}: {
  readonly selectable?: boolean;
  readonly evented?: boolean;
}) => {
  return selectable === false && evented === false;
};

/**
 * Check if there is any difference between the 2 objects
 */
const someDiff =
  (canvas: fabric.Canvas) =>
  (ecogardenObject: EcogardenObjects) =>
  (fabricObject: EcogardenFabricObjects): boolean => {
    return (
      (Object as ObjectEntriesWithTypedKeys)
        .entries(ecogardenObject)
        // eslint-disable-next-line max-nested-callbacks, complexity
        .some(([key, value]) => {
          if (typeof value === "object") {
            if (key === "lock") {
              return isLocked(fabricObject) === value;
            }

            // Ignore size inside objects
            // TODO: we shouldn't be having a to check this here as size
            // shouldn't be nested in our object
            if (key === "size") {
              log.debug("skipping size inside object values");
              return false;
            }

            const fValue = JSON.stringify(
              (fabricObject as EcogardenFabricObject).get(key)
            );
            const ecoValue = JSON.stringify(value);

            // Check if the stringify values are the same.
            if (fValue === ecoValue) {
              return false;
            }
          }

          // We do not reconcile this value as it's only in ecogarden storage
          if (key === "label") {
            return false;
          }

          if (key === "lock") {
            return isLocked(fabricObject) !== value;
          }

          if (key === "fill") {
            if (typeof value === "string") {
              return compareFill(value)(fabricObject);
            }
          }

          // TODO: strokeWidth should be on path and thus be available to check here
          // @ts-ignore
          if (ecogardenObject.type === "path" && key === "strokeWidth") {
            return (
              pathSizeToNumber(value as PathSizes) !== fabricObject.strokeWidth
            );
          }

          if (key === "size") {
            // need to compare size with fontSize
            return (
              (fabricObject as EcogardenFabricTextbox).get("fontSize") !==
              sizeToNumber(value as TextSizes | undefined)
            );
          }

          // Check if there is a value mismatch
          return (fabricObject as EcogardenFabricObject).get(key) !== value;
        })
    );
  };

/**
 * Show reconciliation steps for simular, added, and removed objects between the 2 sets of objects
 */
export const objectsReconcilation =
  (canvas: fabric.Canvas) =>
  (fabricObjects: readonly EcogardenFabricObjects[]) =>
  (objects: readonly EcogardenObjects[]): Reconcilation => {
    log.time(LogLevel.Debug)("reconcilation");
    const profile = profiler(Profiling.Debug);
    const profKey = profile.start("reconcilation");

    const removed = fabricObjects
      .filter((object) => {
        // Skipping objects without id as they are generally not an object we control
        if (object.id === undefined) {
          return false;
        }

        return !objects.some(({ id }) => {
          return object.id === id;
        });
      })
      // casting as string as we should have removed undefined in the previous filter
      .map(({ id }) => id as string);

    const simular = objects
      .filter((ecogardenObject) => {
        if (removed.includes(ecogardenObject.id)) {
          return false;
        }

        // Do a special comparision for lines
        if (ecogardenObject.type === "line") {
          // When saving lines they are effected by how it saves it
          // vs how it may sit in fabric. The x1, y1, x2, y2 values
          // are not to be used as they are not reliable to the current location of these points
          // compare x/y
          // const line = ecogardenObject as EcogardenLine;
          // console.log(
          //   line.id.substring(0, 8),
          //   line.x1,
          //   line.y1,
          //   line.x2,
          //   line.y2
          // );
          // const fObject = fabricObjects.find(
          //   ({ id }) => ecogardenObject.id === id
          // ) as EcogardenFabricLine | undefined;
          log.debug(() => {
            console.log(
              "Skipping simular check on line. Next ecogarden object."
            );
            console.log("Ecogarden Object", ecogardenObject);
            return false;
          });

          return false;
        }

        // Objects in selection do not sync well with fabric objects due to them being in a ActiveSelection group
        if (isInSelection(canvas)(ecogardenObject)) {
          log.info(
            () =>
              `skipping (${ecogardenObject.id.substring(0, 8)} ${
                ecogardenObject.type
              } ${ecogardenObject.subtype}) due to being in a selection`
          );
          return false;
        }

        return fabricObjects.some((fabricObject) => {
          // Skip ActiveSelection group objects. This may conflate due to objects being
          // in groups but the main objects should not be in groups.
          // We are skipping because the object position will not match.
          // TODO: Make objects work even if within a group to work better with selection and allow things to update appropriately.
          // We also don't currently update object state until the selection is cleared on the object, creating some descrepencies
          if (fabricObject.group) {
            log.info(
              () =>
                `skipping (${ecogardenObject.id.substring(0, 8)} ${
                  ecogardenObject.type
                } ${ecogardenObject.subtype}) due to being in a group`
            );
            // update top, left
            // return someDiff(ecogardenObject)(fabricObject)
            // To check group filters we need to account for the top,
            // left being different due to being in a group.
            return false;
          }

          // Not the object we're looking for
          if (fabricObject.id !== ecogardenObject.id) {
            return false;
          }

          // Check if any of the values are different
          return someDiff(canvas)(ecogardenObject)(fabricObject);
        });
      })
      .map((object) => {
        const fabricObject = fabricObjects.find(({ id }) => object.id === id);

        const obj = { id: object.id };

        if (object.type === "polygon") {
          log.debug("diff of polygon", object);
          // log.debug("skipping polygon", object.id);
          return (Object as ObjectEntriesWithTypedKeys)
            .entries(object)
            .reduce<Partial<EcogardenFabricPolygon>>((diff, [key, value]) => {
              if (key === "fill") {
                if (typeof value === "string") {
                  return { ...diff, fill: value };
                }
                return diff;
              }

              if (key === "lock") {
                const result = {
                  selectable: !value,
                  evented: !value,
                };

                return { ...diff, ...result };
              }

              if (value === NaN) {
                log.debug("found NaN", key);
                return diff;
              }

              return diff;
            }, obj);
        }

        return (Object as ObjectEntriesWithTypedKeys)
          .entries(object)
          .reduce<Partial<EcogardenFabricObject>>((diff, [key, value]) => {
            if (key === "visible") {
              log.debug("visible check", value);
            }

            if (key === "lock") {
              const result = {
                selectable: !value,
                evented: !value,
              };

              return { ...diff, ...result };
            }

            if (key === "fill") {
              if (typeof value === "string") {
                return { ...diff, fill: value };
              }
              return diff;
            }

            // font size for textbox
            if (key === "size") {
              return {
                ...diff,
                fontSize: sizeToNumber(value as TextSizes | undefined),
              };
            }

            // @ts-ignore
            if (key === "strokeWidth") {
              return {
                ...diff,
                strokeWidth: pathSizeToNumber(value as PathSizes | undefined),
              };
            }

            // if (object.type === "group") {
            //   if (key === "fill") {
            //     log.debug("skipping updating fill for group", value);
            //     return diff;
            //   }
            // }

            if ((fabricObject as EcogardenFabricObject)?.get(key) === value) {
              return diff;
            }

            if (value === NaN) {
              log.debug("found NaN", key);
              return diff;
            }
            // eslint-disable-next-line max-nested-callbacks
            log.trace(() => {
              console.log(
                `${object.id.substring(
                  0,
                  8
                )} diff detected ${key}. Next ecogardenObject, fabricObject`
              );

              return `ecogarden ${key}: ${value} fabric ${key}: ${(
                fabricObject as EcogardenFabricObject
              )?.get(key)}`;
            });

            return { ...diff, [key]: object[key] };
          }, obj);
      });

    const added = objects.filter((ecogardenObject) => {
      // Skipping adding objects that are being removed
      if (removed.includes(ecogardenObject.id)) {
        return false;
      }
      return !fabricObjects.some(({ id }) => {
        return id === ecogardenObject.id;
      });
    });

    profile.stop(profKey);

    log.info(
      "simular",
      simular.length,
      "added",
      added.length,
      "removed",
      removed.length
    );
    return { simular, added, removed };
  };

type ViewportTransform = readonly [
  number,
  number,
  number,
  number,
  number,
  number
];

export const absoluteTopLeftPointFromViewport = (
  viewport: ViewportTransform
): AbsoluteTopLeftPoint => {
  return {
    x: viewport[4],
    y: viewport[5],
  };
};

/**
 * Sync fabric canvas with stored zoom state
 * TODO: Always using 45 latitude
 */
export const syncCanvasWithZoom =
  (canvas: fabric.Canvas) =>
  (point: AbsoluteTopLeftPoint) =>
  (level: OSMZoomLevel): Promise<void> => {
    // eslint-disable-next-line compat/compat
    return new Promise((resolve) => {
      panCanvas(canvas)(point);
      // TODO: Use user's latitude
      const latitude = 45;
      zoomCanvas(canvas)(OSMZoomLevelToFabricZoomLevel(latitude)(level));
      resolve();
    });
  };

interface Difference {
  type: "CREATE" | "REMOVE" | "CHANGE";
  path: (string | number)[];
  value?: any;
  oldValue?: any;
}

/**
 * Sync objects on canvas with objects state
 * Used with reconcilation for tools like copy/paste, undo, redo and loading new designs
 * TODO improving performance may be helpful in the future
 */
export const syncCanvasWithObjects =
  (canvas: fabric.Canvas) =>
  async ({
    simular,
    added,
    removed,
  }: Reconcilation): Promise<readonly EcogardenFabricObject[]> => {
    // add new
    // update modified
    // remove deleted

    const profile = profiler(Profiling.Debug);
    const profKey = profile.start("sync canvas");

    canvas.forEachObject((object) => {
      // modify and remove
      simular.forEach((ecogardenObject) => {
        if (object.id === ecogardenObject.id) {
          if (Object.keys(ecogardenObject).length <= 1) {
            // id is the only value evaluated here so if it's the only value we will skip it.
            log.debug("skipping due to <= 1 keys to update.");
            return;
          }
          log.trace("setting fabric object with a ecogarden object");
          log.trace(object.toObject());
          log.trace(ecogardenObject);
          if (ecogardenObject.fill && typeof ecogardenObject.fill == "string") {
            log.debug(
              "applying fill to object",
              object.id,
              ecogardenObject.fill
            );
            convertFillColor(ecogardenObject.fill)(object);
          }

          object.set(ecogardenObject);

          // if (object.type === "polygon") {
          //   (object as fabric.Polygon)._setPositionDimensions({});
          // }

          // FIXME Only need to update this on certain values but blanketing all objects
          //https://github.com/fabricjs/fabric.js/wiki/When-to-call-setCoords
          object.setCoords();
          canvas.requestRenderAll();
        }
      });

      canvas.requestRenderAll();

      removed.map((id) => {
        if (object.id === id) {
          log.debug(
            () => "syncing canvas with object', Removing object",
            object.id
          );
          canvas.remove(object);

          canvas.requestRenderAll();
        }
      });

      canvas.requestRenderAll();
    });

    // load new objects
    return loadObjects(added).then((objs) => {
      objs.map((object) => {
        log.debug("new object", object.id);
        canvas.add(object);
      });

      canvas.requestRenderAll();
      profile.stop(profKey);
      return objs;
    });
  };

export const zoomToPoint =
  (canvas: fabric.Canvas) =>
  (point: Point) =>
  (fabricZoomPoint: FabricZoomLevel): void => {
    if (canvas.viewportTransform && canvas.viewportTransform.length >= 5) {
      canvas.zoomToPoint(point, fabricZoomPoint);

      if (canvas.viewportTransform) {
        updateGridBackground(canvas.viewportTransform);
      }

      canvas.requestRenderAll();
    }
  };

export const animatedZoomToPoint =
  (canvas: fabric.Canvas) =>
  (point: Point) =>
  (fabricZoomLevel: FabricZoomLevel): Promise<void> => {
    // eslint-disable-next-line compat/compat
    return new Promise((resolve) => {
      if (canvas.viewportTransform) {
        const left = canvas.viewportTransform[4];
        const top = canvas.viewportTransform[5];
        const initialZoom = canvas.getZoom();

        fabric.util.animate<readonly [number, number, number]>({
          startValue: [initialZoom, left, top],
          endValue: [fabricZoomLevel, point.x, point.y],
          duration: 96,
          easing: fabric.util.ease.easeInOutQuad,
          onChange: ([currentZoom, currentLeft, currentTop]) => {
            zoomToPoint(canvas)(point)(currentZoom);
            canvas.renderAll();
          },
          onComplete: () => {
            canvas.renderAll();
            resolve();
          },
        });
      }
    });
  };

/**
 * Zoom to point and dispatch the pan offset
 */
export const canvasZoomToPoint =
  (canvas: fabric.Canvas) =>
  (dispatch: Dispatch) =>
  (fabricZoomLevel: FabricZoomLevel) =>
  (point: Point): Promise<void> => {
    // eslint-disable-next-line compat/compat
    return new Promise((resolve) => {
      animatedZoomToPoint(canvas)(point)(fabricZoomLevel).then(() => {
        if (canvas.viewportTransform) {
          // const point: Point = {
          //   x: canvas.viewportTransform[4],
          //   y: canvas.viewportTransform[5],
          // };
          batch(() => {
            // dispatch(viewportActions.setTranslation(point));
            // dispatch(viewportActions.setZoom(fabricZoomLevel));
            if (canvas.viewportTransform) {
              dispatch(
                viewportActions.updateViewport(
                  canvas.viewportTransform as unknown as viewportActions.Viewport
                )
              );
            }

            // TODO: Use user latitude
            const latitude = 45;
            dispatch(
              zoomActions.zoom(
                fabricZoomLevelToOSMZoomLevel(latitude)(fabricZoomLevel)
              )
            );
          });

          resolve();
        }
      });
    });
  };

type AbsoluteTopLeftPoint = Point;

/**
 * Pan the canvas and update the state based on the pan
 */
export const canvasPan =
  (canvas: fabric.Canvas) =>
  (dispatch: Dispatch) =>
  (point: AbsoluteTopLeftPoint) => {
    panCanvas(canvas)(point);
    dispatch(viewportActions.setTranslation(point));
  };

/**
 * Pan the canvas
 */
export const panCanvas =
  (canvas: fabric.Canvas) => (point: AbsoluteTopLeftPoint) => {
    canvas.absolutePan(point);
    canvas.renderAll();

    if (canvas.viewportTransform) {
      updateGridBackground(canvas.viewportTransform);
    }
  };

/**
 * Zoom the canvas and update the state based on the pan
 */
export const canvasZoom =
  (canvas: fabric.Canvas) =>
  (dispatch: Dispatch) =>
  (fabricZoomLevel: FabricZoomLevel): Promise<void> => {
    return animatedZoom(canvas)(fabricZoomLevel).then(() => {
      // TODO: Use users latitude
      const latitude = 45;
      const osmZoomLevel =
        fabricZoomLevelToOSMZoomLevel(latitude)(fabricZoomLevel);

      batch(() => {
        if (canvas.viewportTransform) {
          dispatch(
            viewportActions.updateViewport(
              canvas.viewportTransform as unknown as viewportActions.Viewport
            )
          );
        }

        dispatch(zoomActions.zoom(osmZoomLevel));
      });
    });
  };

export const animatedZoom =
  (canvas: fabric.Canvas) =>
  (fabricZoomLevel: FabricZoomLevel): Promise<void> => {
    // eslint-disable-next-line compat/compat
    return new Promise((resolve) => {
      const initialZoom = canvas.getZoom();
      fabric.util.animate<number>({
        startValue: initialZoom,
        endValue: fabricZoomLevel,
        duration: 96,
        easing: fabric.util.ease.easeInOutQuad,
        onChange: (currentZoom) => {
          const center = canvas.getCenter();
          zoomToPoint(canvas)({ x: center.left, y: center.top })(currentZoom);
          // zoomCanvas(canvas)(currentZoom);
          canvas.renderAll();
        },
        onComplete: () => {
          resolve();
        },
      });
    });
  };

export const zoomCanvas =
  (canvas: fabric.Canvas) => (level: FabricZoomLevel) => {
    const { top, left } = canvas.getCenter();

    canvas.zoomToPoint({ x: left, y: top }, level);
    canvas.renderAll();
    if (canvas.viewportTransform) {
      updateGridBackground(canvas.viewportTransform);
    }
  };
