import { IDBPDatabase } from "idb";
import debounce from "lodash/debounce";
import { Root } from "react-dom/client";
import { Store } from "redux";
import { EcogardenDB } from "../design";
import { saveState } from "../design/state";
import { configureStore, RootState } from "./configureStore";
import { objectSchema } from "./objects";
import { SetupApp } from "./ui";

export const validateDesign = async (
	inputDesign: unknown
): Promise<unknown> => {
	if (await objectSchema.isValid(inputDesign)) {
		throw new Error("Invalid design");
	}

	return inputDesign;
};

type Startup = { readonly setupApp: SetupApp };

export const startup =
	(db: Readonly<IDBPDatabase<EcogardenDB>>) =>
	(state: Readonly<Partial<RootState>>) =>
	async <T extends Startup>(
		importApp: Promise<Readonly<T>>
	): Promise<void | readonly [Root | undefined, Store<RootState>]> => {
		const store = configureStore(state);

		const filters = [
			(key: string) => {
				if (key == "canvas" || key == "canvasEvents") {
					return false;
				}

				return true;
			},
		];

		store.subscribe(debounce(saveState(db)(store)(filters), 500));

		return importApp
			.then(({ setupApp }) => setupApp(store))
			.catch((error) => console.error(error));
	};

export const startupPrivate =
	(state: Readonly<Partial<RootState>>) =>
	async <T extends Startup>(
		importApp: Promise<Readonly<T>>
	): Promise<void | readonly [Root | undefined, Store<RootState>]> => {
		const store = configureStore(state);

		return importApp
			.then(({ setupApp }) => setupApp(store))
			.catch((error) => console.error(error));
	};
