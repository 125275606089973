/* All events get {dispatch, e, canvas} */
import { fabric } from "fabric";
import "./fabric";
import { updateGridBackground } from "./fabric/viewport";
import log from "./log";

export const handleResizing = (): void =>
  document.body.classList.add("resizing");

const findDevicePixelRatio = (): number =>
  window.devicePixelRatio > 1 ? window.devicePixelRatio : 1;

export const syncCanvasWithWindow = (canvas: fabric.StaticCanvas): void => {
  // FIXME: Temporary until https://github.com/fabricjs/fabric.js/pull/7537
  fabric.devicePixelRatio = findDevicePixelRatio();
  // log.debug("devicePixelRatio", fabric.devicePixelRatio);

  canvas.setDimensions({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  if (canvas.viewportTransform) {
    updateGridBackground(canvas.viewportTransform);
  }

  // Calculate the height of the viewport. This is to make sure we have all our components on the visible part of the viewport to combat against address bars on the screen.
  //https://web.dev/native-hardware-fullscreen/
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  log.debug(
    `Updated dimensions of canvas to ${window.innerWidth} ${window.innerHeight}`
  );

  canvas.requestRenderAll();
};

// export const handleZoom =
//   (store: Store<RootState>) =>
//   (canvas: fabric.StaticCanvas) =>
//   ({ point, level }: ZoomFabricEvent) => {
//     if (point && level) {
// 			// zoom to
// 			// absolutePan, zoom

// 			store.dispatch(setTranslation(point))
//       // store.dispatch(zoomTo({ level, point }));
//     }

//     updateGridBackground(canvas.viewportTransform);
//   };
