import type { Point } from "./vector";
import * as vector from "./vector";

/**
 * Check if the browser supports Dragging
 */
export const canDrag = (): boolean => {
  const div = document.createElement("div");

  return "draggable" in div || ("ondragstart" in div && "ondrop" in div);
};

export type PointerPoint = {
  clientX: number;
  clientY: number;
};

export const toPoint = ({
  clientX,
  clientY,
}: Readonly<PointerPoint>): Readonly<Point> => {
  return { x: clientX, y: clientY };
};

/**
 * Middle of the 2 pointer points
 */
export const middle =
  (a: PointerPoint) =>
  (b: PointerPoint): Point => {
    return vector.middle(toPoint(a))(toPoint(b));
  };

/**
 * Diff between 2 pointer points
 */
export const diff =
  (a: PointerPoint) =>
  (b: PointerPoint): number => {
    return vector.diff(toPoint(a))(toPoint(b));
  };
