import { fabric } from "fabric";

type MtrControl = { readonly mtr: fabric.Control };

export const applyControls =
  (controlOptions: Partial<fabric.Control>) =>
  <T extends fabric.Object>(object: T): T => {
    object.set("controls", {
      mtr: new fabric.Control({
        x: 0,
        y: -0.5,
        fill:
          getComputedStyle(document.documentElement).getPropertyValue(
            "--accent-bg-color"
          ) + "",
        stroke:
          getComputedStyle(document.documentElement).getPropertyValue(
            "--accent-fg-color"
          ) + "",
        actionHandler: fabric.controlsUtils.rotationWithSnapping,
        cursorStyleHandler: fabric.controlsUtils.rotationStyleHandler,
        offsetY: -40,
        withConnection: true,
        actionName: "rotate",
        ...controlOptions,
      }),
    } as MtrControl);

    object.set("hasControls", true);

    return object;
  };
