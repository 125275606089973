/* eslint-disable max-lines */
import { fabric } from "fabric";
import log from "./log";
import * as vector from "./vector";

/**
 * x, y, x, y
 */
export type Box = readonly [number, number, number, number];

/**
 * x, y
 */
export type Point = readonly [number, number];

export type ValidPoint = readonly [number, number];

/** [x, y], [x, y] */
export type Points = readonly [Point, Point];

/** {x, y} */
export type Coord = {
  readonly x: number;
  readonly y: number;
};

export type BoxMatrix = readonly [number, number, number, number];

// /** Find the center Point of the Box
//  */
// export const center = ([x1, y1, x2, y2]: BoxMatrix): Point => [
//   compareAndAct(x1, x2, (a, b) => b - a),
//   compareAndAct(y1, y2, (a, b) => b - a),
// ];

// /**
//  * Find relative x, y vectors
//  */
// export const relative =
//   (a: Point) =>
//   (b: Point): Point =>
//     [
//       compareAndAct(a[0], b[0], (a, b) => b - a),
//       compareAndAct(a[1], b[1], (a, b) => b - a),
//     ];

/**
 * Transform a Point into a Coord
 */
export const toCoord = ([x, y]: ValidPoint): Coord => ({ x, y });

// /**
//  * Find the minimum coordinates for the 2 Point's
//  */
// export const min =
//   (a: Point) =>
//   (b: Point): Point =>
//     a.map<number>((v, i) =>
//       compareAndAct(v, b[i], Math.min)
//     ) as unknown as Point;

// /**
//  * Find the maximum coordinates for the 2 points
//  */
// export const max =
//   (a: Point) =>
//   (b: Point): Point =>
//     a.map((v, i) => compareAndAct(v, b[i], Math.max)) as unknown as Point;

// /**
//  * Find the center Point of 2 Boxes
//  */
// export const relativeCenters =
//   (a: BoxMatrix) =>
//   (b: BoxMatrix): Point =>
//     relative(center(a))(center(b));

/**
 * Transform a Box into 2 Point's
 */
export const toPoints = ([tlX, tlY, brX, brY]: BoxMatrix): readonly [
  Point,
  Point
] => [
  [tlX, tlY],
  [brX, brY],
];

/**
 * Transform a list of 2 Point's into a Box
 */
export const pointsToBox = ([tl, br]: Points): BoxMatrix => [
  tl[0],
  tl[1],
  br[0],
  br[1],
];

// /**
//  * Find bounding Box for 2 Box's
//  */
// export const bounding =
//   (a: BoxMatrix) =>
//   (b: BoxMatrix): BoxMatrix =>
//     pointsToBox(
//       [toPoints(a), toPoints(b)].reduce((acc, [tl, br]) => [
//         min(tl)(acc[0]),
//         max(br)(acc[1]),
//       ])
//     );

export const emptyBoxMatrix = ():
  | BoxMatrix
  | readonly [undefined, undefined, undefined, undefined] => [
  undefined,
  undefined,
  undefined,
  undefined,
];

type Constraint = {
  readonly left: number;
  readonly top: number;
  readonly width: number;
  readonly height: number;
};

export const constrain =
  (a: Constraint) =>
  (b: Constraint): readonly [number, number, number, number, number] => {
    const scale = Math.min(b.height / a.height, b.width / a.width);

    log.debug("constrain scale", scale);
    const w = a.width * scale;
    const h = a.height * scale;

    log.debug("constrain new", w, h);

    // original center
    const c = {
      x: b.left + b.width / 2,
      y: b.top + b.height / 2,
    };

    log.debug("constrain original", c.x, c.y);

    // new center
    const nc = {
      x: c.x - w / 2,
      y: c.y - h / 2,
    };

    log.debug("constain center", nc.x, nc.y);

    return [nc.x, nc.y, w, h, scale];
  };

/**
 * Get the absolute boundaries of the objects
 */
// eslint-disable-next-line max-lines-per-function
export const objectsBoundaries = (
  objects: readonly Readonly<fabric.Object>[]
): readonly [number, number, number, number] | readonly [] => {
  return objects
    .flatMap((object) => {
      if (!object.width || !object.height) {
        console.log("invalid width or height", object.width, object.height);
        return [];
      }

      const w = object.width / 2;
      const h = object.height / 2;

      const transform =
        (transform?: readonly number[]) => (point: Readonly<fabric.Point>) =>
          !transform
            ? new fabric.Point(0, 0)
            : fabric.util.transformPoint(point, transform);

      const transformPoint = transform(object.calcTransformMatrix());

      const boundaries: readonly Readonly<fabric.Point>[] = [
        transformPoint(new fabric.Point(-w, -h)),
        transformPoint(new fabric.Point(w, -h)),
        transformPoint(new fabric.Point(-w, h)),
        transformPoint(new fabric.Point(w, h)),
      ];

      return boundaries;
    })
    .reduce<readonly [number, number, number, number] | readonly []>(
      (accumulator: readonly number[], boundary) => {
        // Should be inital accumulator so just set the first value
        if (!accumulator || accumulator.length != 4) {
          return [boundary.x, boundary.y, boundary.x, boundary.y];
        }

        return [
          Math.min(accumulator[0], boundary.x),
          Math.min(accumulator[1], boundary.y),
          Math.max(accumulator[2], boundary.x),
          Math.max(accumulator[3], boundary.y),
        ];
      },
      []
    );
};

type TopLeftWidthHeight = {
  readonly left: number;
  readonly top: number;
  readonly width: number;
  readonly height: number;
};

export const rect =
  (tl: fabric.Point | vector.Point) =>
  (br: fabric.Point | vector.Point): TopLeftWidthHeight => {
    return { top: tl.y, left: tl.x, width: br.x - tl.x, height: br.y - tl.y };
  };

export const boundariesReducer = (
  acc: readonly number[],
  point: { readonly x: number; readonly y: number }
) => {
  if (!acc || acc.length != 4) {
    return [point.x, point.y, point.x, point.y];
  }
  return [
    Math.min(acc[0], point.x),
    Math.min(acc[1], point.y),
    Math.max(acc[2], point.x),
    Math.max(acc[3], point.y),
  ];
};

export const calcPolygonArea = (vertices: { x: number; y: number }[]) => {
  var total = 0;

  for (var i = 0, l = vertices.length; i < l; i++) {
    var addX = vertices[i].x;
    var addY = vertices[i == vertices.length - 1 ? 0 : i + 1].y;
    var subX = vertices[i == vertices.length - 1 ? 0 : i + 1].x;
    var subY = vertices[i].y;

    total += addX * addY * 0.5;
    total -= subX * subY * 0.5;
  }

  return Math.abs(total);
};
