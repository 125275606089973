import { keyframes } from "@emotion/css";

const slideDown = keyframes`
	0% {
		opacity: 0;
		transform: translateY(-100%);
	}

	50% {
		opacity: .25;
	}

	90% {
		opacity: .9;
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
`;

export default slideDown;
