import { fabric } from "fabric";
import { EcogardenFabricPolygon } from "../../fabric/objects";

type ActionHandler = (
  eventData: Readonly<MouseEvent>,
  transform: Readonly<fabric.Transform>,
  x: number,
  y: number
) => boolean;

// TODO: resolve global
export let __isControlDragging = false;

export const setIsControlDragging = (isDragging: boolean): void => {
  // eslint-disable-next-line fp/no-mutation
  __isControlDragging = isDragging;
};

/**
 * define a function that will define what the control does
 * this function will be called on every mouse move after a control has been
 * clicked and is being dragged.
 * The function receive as argument the mouse event, the current trasnform object
 * and the current position in canvas coordinate
 * transform.target is a reference to the current object being transformed,
 */
// eslint-disable-next-line max-params, max-lines-per-function
const actionHandler: ActionHandler = (_eventData, transform, x, y) => {
  const polygon = transform.target as fabric.Polygon;
  const currentControl = polygon.controls[polygon.__corner];
  const mouseLocalPosition = polygon.toLocalPoint(
    new fabric.Point(x, y),
    "center",
    "center"
  );
  const polygonBaseSize = polygon._getNonTransformedDimensions();
  const size = polygon._getTransformedDimensions(0, 0);
  const finalPointPosition = new fabric.Point(
    (mouseLocalPosition.x * polygonBaseSize.x) / size.x + polygon.pathOffset.x,
    (mouseLocalPosition.y * polygonBaseSize.y) / size.y + polygon.pathOffset.y
  );

  if (!polygon.points) {
    // eslint-disable-next-line fp/no-mutation
    polygon.points = [];
  }

  // For some reason polygon.points was readonly so we make a shallow copy and then add the final point position.
  const points = [...polygon.points];

  // eslint-disable-next-line fp/no-mutation
  points[currentControl.pointIndex] = finalPointPosition;

  // eslint-disable-next-line fp/no-mutation
  polygon.set("points", points);

  // After updating points we should update the position dimensions
  polygon._setPositionDimensions({});

  return true;
};

/**
 *  define a function that can keep the polygon in the same position when we change its
 * width/height/top/left.
 * @param {number} anchorIndex - index of the anchor
 * @param {(eventData: fabric.IEvent, transform: { target: fabric.Object }, x: number, y: number) => boolean} fn -  action function
 * @return {(eventData: fabric.IEvent, transform: { target: fabric.Object }, x: number, y: number) => boolean} if the action was performed
 */
// eslint-disable-next-line max-lines-per-function
function anchorWrapper(
  anchorIndex: number,
  function_: ActionHandler
): ActionHandler {
  // eslint-disable-next-line max-params, max-lines-per-function
  const callback: ActionHandler = (eventData, transform, x, y) => {
    const fabricObject = transform.target as fabric.Polygon;

    if (!fabricObject.points) {
      console.error("points was not present on fabric object");
      // eslint-disable-next-line fp/no-mutation
      fabricObject.points = [];
    }

    // eslint-disable-next-line fp/no-mutation
    __isControlDragging = true;

    const absolutePoint = fabric.util.transformPoint(
      new fabric.Point(
        fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x,
        fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y
      ),
      fabricObject.calcTransformMatrix()
    );
    const actionPerformed = function_(eventData, transform, x, y);

    // fabricObject._setPositionDimensions({});
    const polygonBaseSize = fabricObject._getNonTransformedDimensions();
    const newX =
      (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) /
      polygonBaseSize.x;
    const newY =
      (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) /
      polygonBaseSize.y;

    // TODO: setPositionByOrigin also allows 0-1 range for the origin position
    fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);

    return actionPerformed;
  };

  return callback;
}

// define a function that can locate the controls.
// this function will be used both for drawing and for interaction.
// eslint-disable-next-line max-params
function polygonPositionHandler(
  _dim: {
    readonly x: number;
    readonly y: number;
  },
  _finalMatrix: readonly number[],
  fabricObject: fabric.Polygon
): fabric.Point {
  if (!fabricObject.points) {
    // eslint-disable-next-line fp/no-mutation, functional/immutable-data
    fabricObject.points = [];
  }

  // TODO: Remove this reference to this.pointIndex and then we can remove the ts-ignore
  // @ts-ignore
  const x = fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x;
  // @ts-ignore
  const y = fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y;

  return fabric.util.transformPoint(
    new fabric.Point(x, y),
    fabric.util.multiplyTransformMatrices(
      [...(fabricObject.canvas?.viewportTransform ?? [])],
      [...fabricObject.calcTransformMatrix()]
    )
  );
}

/**
 * Apply the polygon controls to the object
 */
// eslint-disable-next-line max-lines-per-function
export const applyControls = <T extends EcogardenFabricPolygon>(
  object: T
): T => {
  const points = object.points ?? [];
  const lastControl = points.length - 1;

  // eslint-disable-next-line max-params, fp/no-mutation, functional/immutable-data, unicorn/prefer-object-from-entries
  object.controls = points.reduce(function (
    accumulator: Record<string, fabric.Control>,
    _point,
    index
  ) {
    // eslint-disable-next-line fp/no-mutation, functional/immutable-data
    accumulator["p" + index] = new fabric.Control({
      positionHandler: polygonPositionHandler,
      actionHandler: anchorWrapper(
        index > 0 ? index - 1 : lastControl,
        actionHandler
      ),
      actionName: "modifyPolygon",
      // Custom property
      pointIndex: index,

      fill: getComputedStyle(document.documentElement).getPropertyValue(
        "--accent-bg-color".toString() + "90"
      ),
      stroke: getComputedStyle(document.documentElement)
        .getPropertyValue("--accent-fg-color")
        .toString(),
    });

    // if (lastControl == index) {
    // 	acc["p" + index]
    // }
    return accumulator;
  },
  {});

  return object;
};
