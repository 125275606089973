/**
 * Fabric events need to support objects
 */
import type { fabric } from "fabric";
import { toFabricPoint } from "../fabric";
import { updateGridBackground } from "../fabric/viewport";
import log from "../log";
import type { Point } from "../vector";
import { delta } from "../vector";

type Store<T> = {
  readonly setState: (newState: T) => void;
  readonly getState: () => T;
};

/**
 * TODO
 * Canvas pans in the wrong direction
 * need to get the canvas to pan correctly.
 * fixed the canvas using the pointer location at start.
 */

/**
 * Wrapper for fabric.IEvent to allow Mouse Events
 * All arguments are optional
 */
export interface MouseFabricEvent extends Partial<fabric.IEvent> {
  readonly e?: MouseEvent;
}

/**
 * Wrapper for fabric.IEvent events specifically
 */
export interface FabricEvent<T extends Event> extends fabric.IEvent {
  readonly e: T;
}

const useMutatingStore = <T>(previousState: T): Store<T> => {
  let state = previousState;

  return {
    setState(newState: T): void {
      state = newState;
    },

    getState(): T {
      return state;
    },
  };
};

export type MousePanState = {
  lastPointerLocation: Point | undefined;
  active: boolean;
};

export interface SelectableCanvas<T extends Event> extends FabricCanvas<T> {
  selectable?: boolean;
}

export type PointerFabricCanvas<T extends UIEvent> = FabricCanvas<T>;

export interface FabricCanvas<T extends Event> extends fabric.Canvas {
  // getPointer(e: Event, ignoreZoom?: boolean | undefined): { x: number; y: number; };
  // getPointer(e: Event, ignoreZoom: boolean): { x: number; y: number; };
  // readonly getPointer: (e: T, ignoreZoom?: boolean) => Point;

  /**
   * Observes specified event
   * @param eventName Event name (eg. 'after:render')
   * @param handler Function that receives a notification when an event of the specified type occurs
   */
  on(
    eventName: string,
    handler: (e: FabricEvent<T>) => void
  ): fabric.StaticCanvas;

  /**
   * Observes specified event
   * @param eventName Object with key/value pairs (eg. {'after:render': handler, 'selection:cleared': handler})
   */
  on(events: {
    readonly [eventName: string]: (e: FabricEvent<T>) => void;
  }): fabric.StaticCanvas;

  /**
   * Stops event observing for a particular event handler. Calling this method
   * without arguments removes all handlers for all events
   * @param eventName Event name (eg. 'after:render') or object with key/value pairs (eg. {'after:render': handler, 'selection:cleared': handler})
   * @param handler Function to be deleted from EventListeners
   */
  off(
    eventName?: string | unknown,
    handler?: (e: FabricEvent<T>) => void
  ): fabric.StaticCanvas;

  readonly __onMouseDown: (e: MouseEvent) => void;
  readonly __onMouseUp: (e: MouseEvent) => void;
  readonly __onMouseMove: (e: MouseEvent) => void;

  readonly cancelRequestedRender: () => void;
}

/**
 * Pan the canvas
 */
const panCanvas =
  (canvas: PointerFabricCanvas<MouseEvent>) =>
  (last: Point) =>
  (e: MouseFabricEvent) => {
    const event = e.e;
    if (!event) {
      return undefined;
    }

    const point = canvas.getPointer(event, true);

    // log.debug("panCanvas", "canvas Pointer location", point);

    // const point = getPointer(e);
    const relative = delta(point)(last);

    // const topLeftPoint = toFabricPoint(relative);

    // log.debug(
    //   "panCanvas",
    //   "toFabricPoint delta from last location",
    //   topLeftPoint
    // );

    // The following is commented out but should actually pan here.
    canvas.relativePan(relative as fabric.Point);

    canvas.viewportTransform && updateGridBackground(canvas.viewportTransform);

    canvas.requestRenderAll();

    return point;
  };

// Need to allow object for passing down Point and various objects.
type MousePanEvent = readonly [
  "mouse:down" | "mouse:up" | "mouse:move",
  (e: FabricEvent<MouseEvent>) => void
];

/**
 * Handles update (start/stop) pan events
 */
type MousePanEventHandler = (
  store: Store<MousePanState>
) => (canvas: SelectableCanvas<MouseEvent>) => (e: MouseFabricEvent) => void;

/**
 * Start mouse pan handler on fabric.Canvas events
 */
export const start: MousePanEventHandler =
  (store) =>
  (canvas) =>
  (e): void => {
    const lastPointerLocation = canvas.getPointer(e.e as MouseEvent, true);
    store.setState({
      lastPointerLocation,
      active: true,
    });

    canvas.defaultCursor = "grabbing";

    log.debug("mousePan", "start", lastPointerLocation);

    document.dispatchEvent(new CustomEvent("panstart"));
  };

/**
 * Stop mouse pan handler on fabric.Canvas events
 */
export const stop: MousePanEventHandler =
  (store) =>
  (canvas) =>
  (e): void => {
    const { lastPointerLocation, active } = store.getState();

    if (!active || lastPointerLocation === undefined) {
      return;
    }

    const topLeft = panCanvas(canvas)(lastPointerLocation as Point)(e);

    store.setState({
      lastPointerLocation: undefined,
      active: false,
    });

    canvas.defaultCursor = "grab";

    log.debug("mousePan", "stop", topLeft);
    document.dispatchEvent(
      new CustomEvent("panstop", { detail: canvas.getVpCenter() })
    );
  };

/**
 * Move canvas relative to last point on fabric.Canvas events
 */
export const move: MousePanEventHandler =
  (store) =>
  (canvas) =>
  (e): void => {
    const { lastPointerLocation, active } = store.getState();

    if (!active || lastPointerLocation === undefined) {
      return;
    }

    const pointerLocation = canvas.getPointer(e.e as MouseEvent, true);

    const topLeftPoint = panCanvas(canvas)(lastPointerLocation)(e);

    log.trace("mousePan", "move", lastPointerLocation, topLeftPoint);

    store.setState({ lastPointerLocation: pointerLocation, active: true });
    document.dispatchEvent(
      new CustomEvent("panmove", { detail: canvas.getVpCenter() })
    );
  };

export type MousePan = (
  canvas: PointerFabricCanvas<MouseEvent>
) => readonly MousePanEvent[];

/**
 * Mouse Pan Event Handlers for fabric.Canvas events
 */
export const mousePan: MousePan = (canvas): readonly MousePanEvent[] => {
  const store = useMutatingStore<MousePanState>({
    lastPointerLocation: undefined,
    active: false,
  });

  canvas.fireMiddleClick = true;

  return [
    ["mouse:down", start(store)(canvas)],
    ["mouse:up", stop(store)(canvas)],
    ["mouse:move", move(store)(canvas)],
  ];
};

type BindCanvas = (
  canvas: SelectableCanvas<MouseEvent>
) => (events: readonly MousePanEvent[]) => () => void;

/**
 * Bind the events to the canvas
 * return unbind()
 */
export const bind: BindCanvas =
  (canvas) =>
  (events): (() => void) => {
    events.map(([key, eventHandler]) => {
      canvas.on(key, eventHandler);
    });

    return function unbind(): void {
      events.map(([key, eventHandler]) => {
        canvas.off(key, eventHandler);
      });
    };
  };

export type EnableMousePan = (
  canvas: PointerFabricCanvas<MouseEvent>
) => () => void;

/**
 * Enable Mouse Pan
 * return disableMousePan()
 */
export const bindMousePan: EnableMousePan = (canvas) =>
  bind(canvas)(mousePan(canvas));

export default bindMousePan;
