import { fabric } from "fabric";

export const applyControls = <T extends fabric.Object>(object: T): T => {
  const defaultControls = fabric.Textbox.prototype.controls;

  // Horizontal + Rotating controls
  const controls = {
    mtr: defaultControls.mtr,
    ml: defaultControls.ml,
    mr: defaultControls.mr,
  };

  const fill =
    getComputedStyle(document.documentElement).getPropertyValue(
      "--accent-bg-color"
    ) + "40";
  const stroke =
    getComputedStyle(document.documentElement).getPropertyValue(
      "--accent-fg-color"
    ) + "80";

  controls.mtr.fill = fill;
  controls.mtr.stroke = stroke;
  controls.ml.fill = fill;
  controls.ml.stroke = stroke;
  controls.mr.fill = fill;
  controls.mr.stroke = stroke;

  object.set("controls", controls);

  return object;
};
