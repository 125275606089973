import { keyframes } from "@emotion/css";
const slideUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export default slideUp;
