import { fabric } from "fabric";

export const applyControls = <T extends fabric.Object>(object: T): T => {
  object.set("controls", {
    ...fabric.Object.prototype.controls,
    // @ts-ignore
    mtr: new fabric.Control({
      ...fabric.Object.prototype.controls.mtr,
      // Move mtr up to account for larger corner size
      offsetY: object.cornerSize ? -(object.cornerSize * 1.5) : -64,
    }),
  });
  object.set("hasControls", true);
  object.set("hasBorders", true);
  return object;
};
