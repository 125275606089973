import { fabric } from "fabric";

type Control = { readonly mtr: fabric.Control; tl: fabric.Control };

const createControl = (controlOptions: Partial<fabric.Control>) =>
  new fabric.Control({
    fill:
      getComputedStyle(document.documentElement).getPropertyValue(
        "--accent-bg-color"
      ) + "",
    stroke:
      getComputedStyle(document.documentElement).getPropertyValue(
        "--accent-fg-color"
      ) + "",
    // @ts-ignore
    actionHandler: fabric.controlsUtils.scalingEqually,

    // @ts-ignore
    cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
    withConnection: true,
    // actionName: "rotate",
    ...controlOptions,
  });

export const applyControls =
  (controlOptions: Partial<fabric.Control>) =>
  <T extends fabric.Object>(object: T): T => {
    object.setControlsVisibility({
      mb: false,
      ml: false,
      mt: false,
      mr: false,
    });
    object.set("controls", fabric.Object.prototype.controls);
    // object.set("controls", {
    //   mtr: new fabric.Control({
    //     x: 0,
    //     y: -0.5,
    //     fill:
    //       getComputedStyle(document.documentElement).getPropertyValue(
    //         "--accent-bg-color"
    //       ) + "",
    //     stroke:
    //       getComputedStyle(document.documentElement).getPropertyValue(
    //         "--accent-fg-color"
    //       ) + "",
    //     actionHandler: fabric.controlsUtils.rotationWithSnapping,
    //     cursorStyleHandler: fabric.controlsUtils.rotationStyleHandler,
    //     offsetY: -80,
    //     withConnection: true,
    //     actionName: "rotate",
    //     ...controlOptions,
    //   }),

    //   tl: createControl({
    //     x: -0.5,
    //     y: -0.5,
    //     offsetX: -22,
    //     offsetY: -22,
    //     ...controlOptions,
    //   }),
    //   tr: createControl({
    //     x: -0.5,
    //     y: -0.5,
    //     offsetX: -22,
    //     offsetY: -22,
    //     ...controlOptions,
    //   }),
    //   bl: createControl({
    //     x: -0.5,
    //     y: -0.5,
    //     offsetX: -22,
    //     offsetY: -22,
    //     ...controlOptions,
    //   }),

    //   br: createControl({
    //     x: -0.5,
    //     y: -0.5,
    //     offsetX: -22,
    //     offsetY: -22,
    //     ...controlOptions,
    //   }),
    // } as Control);

    object.set("hasControls", true);
    object.set("hasBorders", true);

    return object;
  };
