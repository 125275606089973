import { useEffect } from "react";
import Mousetrap, { ExtendedKeyboardEvent } from "mousetrap";

export default function useMousetrap(keybindings: {
  [key: string]: (e: ExtendedKeyboardEvent) => void;
}) {
  useEffect(() => {
    Object.entries(keybindings).forEach(([key, fn]) =>
      Mousetrap.bind(`${key}`, (e) => {
        if ("preventDefault" in e) {
          e.preventDefault();
        }

        return fn(e);
      })
    );
    return function cleanup() {
      Object.keys(keybindings).forEach((key) => Mousetrap.unbind(key));
    };
  }, [keybindings]);
}
